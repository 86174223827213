<template>
  <feature-card @close="close">
    <template v-slot:name>
      {{ name }}
    </template>
    <template v-slot:subtitle>
      {{ feature.template.name }}
      <br/>
      <span v-if="geometryInfoInfo" v-html="geometryInfoInfo"/>
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        :counters="counters"
        class="px-3 mt-1"
        @attachData="attachData"
        @calculate="calculate"
        @edit="$emit('edit')"
        @history="history"
        @openIssues="openIssues"
        @remove="openConfirmationDialog"
        @share="share"
      />

      <v-tabs
        v-model="tab"
        bg-color="primary"
        class="mt-2"
        density="compact"
        fixed-tabs
      >
        <v-tab value="one">{{$t('overview')}}</v-tab>
        <v-tab
          v-if="feature.relatedInfoInDB && feature.relatedInfoInDB.length"
          value="two"
          @click="() => $refs.dbFields ? $refs.dbFields.back() : ''"
        >
          {{ $t('et') }}
        </v-tab>
        <v-tab value="three" v-if="images.length">{{$t('photos')}}</v-tab>
      </v-tabs>

      <layer-poi-dialog
        ref="layerPoiDlg"
        actions-toolbar
        @update="refresh"
      />
      <layer-poi-changelog-dialog ref="changelogDlg"/>
      <layer-poi-issues-dialog
        ref="issuesDialog"
        @on-save-issue="updateCounter"
      />
      <attached-data ref="attachedDataDlg" :lp="feature" @got-attached-files="setAttachedFiles"
                     @data-updated="updateCounter"/>
      <share-data-dialog ref="shareDataDialog"/>
      <confirmation-dialog
        ref="removeConfirmationDialog"
        :title="$t('deleteConfirmation')"
        @confirm="remove"
      />
    </template>
    <template v-slot:fields>
      <v-tabs-items v-model="tab" style="position: relative">
        <v-tab-item class="pt-3">
          <layer-poi-fields
            :poi="feature"
            :readonly-mode="!isAuthenticated"
            @refresh="refresh"
          />
        </v-tab-item>

        <v-tab-item v-if="feature.relatedInfoInDB && feature.relatedInfoInDB.length">
          <feature-db-fields
            ref="dbFields"
            :infoFromDb="feature.relatedInfoInDB"
            @showBackBtn="updateShowBackBtn"
          />
        </v-tab-item>

        <v-tab-item>
          <v-expand-transition>
            <lp-image-carousel :images="images" class="mb-3"/>
          </v-expand-transition>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template
      v-slot:actions
    >
      <v-tabs-items v-model="tab" class="full-width">
        <v-tab-item v-if="isAuthenticated">
          <div class="full-width d-flex flex-row">
            <v-btn
              outlined
              @click="$refs.layerPoiDlg.open(feature)"
            >
              {{ $t('details') }}
            </v-btn>
            <v-spacer/>
            <v-btn
              color="primary"
              outlined
              @click="$emit('save')"
            >
              {{ $t('save') }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="full-width d-flex flex-row">
            <v-slide-y-reverse-transition>
              <v-btn
                v-show="showBackBtn"
                outlined
                @click="() => $refs.dbFields.back()"
              >
                <v-icon>mdi-backburger</v-icon>
              </v-btn>
            </v-slide-y-reverse-transition>
          </div>
        </v-tab-item>

        <v-tab-item>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </feature-card>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import FeatureCard from '@/components/map/FeatureCard'
import { EventBus } from '@/event-bus'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import { ATTACHED_DATA, EDIT, HISTORY, OPEN_ISSUES, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import { getLinkToLayerPoi, getNameOfLayerPoi, saveLayerPoi } from '@/components/layer-poi/layer-poi-service'
import ActionToolbar from '@/components/utils/ActionToolbar'
import LayerPoiChangelogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'
import AttachedData from '@/components/layer-poi/attachments/AttachedData.vue'
import LayerPoiIssuesDialog from '@/components/issue/LayerPoiIssuesDialog.vue'
import { poiMixin } from '@/mixins/poi-mixin'
import messages from '@/componet-locale/layer-poi-feature-card/messages'
import sharedMessages from '@/componet-locale/feature-card/messages'
import LpImageCarousel from '@/components/map/images/LpImageCarousel.vue'
import EtItemCard from '@/components/et/EtItemCard.vue'
import FeatureDbFields from '@/components/map/FeatureDbFields.vue'

export default {
  name: 'LayerPoiFeatureCard',
  i18n: { messages, sharedMessages },
  components: {
    FeatureDbFields,
    EtItemCard,
    LpImageCarousel,
    LayerPoiIssuesDialog,
    AttachedData,
    LayerPoiChangelogDialog,
    ActionToolbar,
    ConfirmationDialog,
    ShareDataDialog,
    LayerPoiDialog,
    FeatureCard,
    LayerPoiFields
  },
  mixins: [mapMeasureMixin, poiMixin],
  props: {
    feature: Object
  },
  data: () => ({
    tab: null,
    attachedFiles: [],
    showBackBtn: false
  }),
  created () {
    this.updateCounter()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.feature.id }
        })
        .then(() => {
          this.refresh()
          this.close()
        })
    },
    save () {
      saveLayerPoi(this.feature)
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    openIssues () {
      this.$refs.issuesDialog.open(this.feature)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.feature)
    },
    calculate () {
      let source = new Vector({
        features: (
          new GeoJSON({
            featureProjection: 'EPSG:3857'
          }))
          .readFeatures([this.feature])
      })
    },
    share () {
      this.$refs.shareDataDialog.share(
        getLinkToLayerPoi(this.feature)
      )
    },
    history () {
      this.$refs.changelogDlg.open(this.feature)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    updateCounter () {
      this.populateCounters(this.feature.id)
    },
    setAttachedFiles (files) {
      this.attachedFiles = files
    },
    updateShowBackBtn (v) {
      this.showBackBtn = v
    }
  },
  computed: {
    geometryInfoInfo () {
      let geometry = this.getGeometry(this.feature)
      switch (this.feature.geometry.type) {
        case 'LineString' :
        case 'MultiLineString' :
          return 'Длина объекта: ' + this.formatLength(geometry, { projection: 'EPSG:4326' })
        case 'Polygon':
        case 'MultiPolygon' :
          return 'Площадь объекта: ' + this.formatArea(geometry, { projection: 'EPSG:4326' })
      }
    },
    actions () {
      return [OPEN_ISSUES, ATTACHED_DATA, SHARE, HISTORY, EDIT, REMOVE]
        .filter(action => this.isAuthenticated || !action.onlyAuthenticated)
    },
    name () {
      return getNameOfLayerPoi(this.feature)
    },
    images () {
      return this.attachedFiles.filter(file => ['png', 'jpg', 'gif', 'jpeg'].includes(file.type))
    }
  }
}
</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      style="z-index: 1001"
      width="600"
    >
      <v-card v-if="dialog">
        <v-card-title class="title pb-0">
          {{ wmsLayer ? wmsLayer.name : this.$t('title') }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="caption py-3">
            {{ $t('layerCreationDate') }}: {{ formatDate(wmsLayer.creationDate) }}
          </div>
          <!-- TODO add base layer changer -->
          <div style="width: 100%; height: 300px; position: relative; border-radius: 8px; overflow: hidden">
            <vl-map
              id="map"
              ref="map"
              class="d-map"
              data-projection="EPSG:4326"
              load-tiles-while-animating
              load-tiles-while-interacting
              @created="mapCreated"
            >
              <vl-view
                ref="view"
                :center="[1,1]"
                :zoom="1"
              />
              <vl-layer-tile :z-index="1">
                <vl-source-osm/>
              </vl-layer-tile>
              <vl-layer-tile :z-index="2">
                <vl-source-wms
                  ref="wmsLayer"
                  :layers="wmsLayer.layerId"
                  :url="url"
                  server-type="geoserver"
                />
              </vl-layer-tile>
              <vl-layer-vector ref="vectorLayer" :z-index="3">
                <vl-source-vector>
                  <vl-feature>
                    <component
                      :is="getFeatureComponent(feature)"
                      v-if="feature && feature.geometry"
                      :coordinates="feature.geometry.coordinates"
                    />
                    <vl-style-box>
                      <vl-style-circle :radius="10">
                        <vl-style-stroke
                          :width="6"
                          color="red"
                        />
                      </vl-style-circle>
                      <vl-style-stroke
                        :width="5"
                        color="red"
                      />
                    </vl-style-box>
                  </vl-feature>
                </vl-source-vector>
              </vl-layer-vector>
            </vl-map>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            @click="close"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn class="white--text" color="#2d89ff" @click="openMapForLayer">
            <v-icon class="mr-1">map</v-icon>
            {{ this.$t('goToMap') }}
          </v-btn>
          <!-- TODO feature fields -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { poiMixin } from '@/mixins/poi-mixin'
import messages from '@/componet-locale/wms-feature-dialog/messages'
import WmsLayer from '@/components/map/layer/WmsLayer.vue'
import { WMSGetFeatureInfo } from 'ol/format'
import { transform } from 'ol/proj'
import { EventBus } from '@/event-bus'

export default {
  name: 'WmsFeatureDialog',
  components: { WmsLayer },
  i18n: { messages },
  mixins: [poiMixin],
  data: () => ({
    dialog: false,
    feature: null,
    wmsLayer: null
  }),
  methods: {
    async open (feature, layer) {
      this.feature = feature
      this.wmsLayer = layer
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.wmsLayer = null
      this.feature = null
    },
    async getFeature (coordinate, resolution) {
      await new Promise(resolve => setTimeout(() => resolve(), 300))
      let source = this.$refs.wmsLayer.$source
      let url = source.getGetFeatureInfoUrl(
        coordinate,
        resolution,
        'EPSG:3857',
        { 'INFO_FORMAT': 'application/vnd.ogc.gml' }
      )
      let { data } = await this.$axios.get(url)
      let parser = new WMSGetFeatureInfo()
      return parser.readFeatures(data)[0]
    },
    async mapCreated (vm) {
      this.vm = vm
      const resolution = vm.$map.getView().getResolution()
      const coordinate = transform(this.feature.geometry.coordinates.flat(Infinity).slice(0, 2), 'EPSG:4326', 'EPSG:3857')
      const feature = await this.getFeature(coordinate, resolution)
      await this.$refs.view.fit(this.feature.geometry, { duration: 500 })
    },
    getFeatureComponent (feature) {
      switch (feature.geometry.type) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + feature.geometry.type)
      }
    },
    openMapForLayer (e) {
      this.$axios.get('map/find-by-layer', {
        params: {
          layerId: this.wmsLayer.id
        }
      })
        .then(res => {
          if (res.data.length === 0) throw new Error('Map not found')
          return res.data[0]
        })
        .then(map => this.openInNewTab(map, e, null, this.feature))
        .catch(() => EventBus.$emit('showInfoMessage', this.$t('mapNotFound')))
    }
  },
  computed: {
    url () {
      return window.location.origin + '/geoserver/wms'
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('span',[_c('v-icon',{staticClass:"handle pointer pr-3",attrs:{"small":""}},[_vm._v("drag_indicator")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})],1)]),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-1"},[_c('v-select',{staticClass:"mb-3",attrs:{"items":_vm.headers,"label":_vm.$t('field'),"dense":"","hide-details":"","item-text":"fieldName","item-value":"id","multiple":"","outlined":""},model:{value:(_vm.rule.headerIds),callback:function ($$v) {_vm.$set(_vm.rule, "headerIds", $$v)},expression:"rule.headerIds"}}),(_vm.rule.headerIds.length > 0)?_c('div',[_c('v-select',{staticClass:"mb-3",attrs:{"items":_vm.predicates,"label":_vm.$t('condition'),"dense":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item))+" ")]}}],null,false,213305884),model:{value:(_vm.rule.predicate),callback:function ($$v) {_vm.$set(_vm.rule, "predicate", $$v)},expression:"rule.predicate"}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"label":_vm.$t('value'),"dense":"","hide-details":"","outlined":""},model:{value:(_vm.rule.value),callback:function ($$v) {_vm.$set(_vm.rule, "value", $$v)},expression:"rule.value"}}),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"648","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',_vm._g({staticClass:"px-3 py-2 pointer transition-fast-in-fast-out",class:hover ? 'elevation-1' : '',style:({
                          border: "1px solid black",
                          backgroundColor: _vm.rule.backgroundMode ? _vm.rule.backgroundColor : _vm.DEFAULT_COLORS.BACKGROUND_COLOR,
                          color: _vm.rule.textColorMode ? _vm.rule.textColor : _vm.DEFAULT_COLORS.TEXT_COLOR,
                          borderRadius: '4px'
                        })},on),[_vm._v(" "+_vm._s(_vm.$t('pickColor'))+" ")])]}}],null,true)})]}}],null,false,3011630589)},[_c('v-card',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ma-3"},[_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"label":_vm.$t('background'),"dense":"","hide-details":""},model:{value:(_vm.rule.backgroundMode),callback:function ($$v) {_vm.$set(_vm.rule, "backgroundMode", $$v)},expression:"rule.backgroundMode"}})],1),_c('v-color-picker',{attrs:{"disabled":!_vm.rule.backgroundMode,"hide-canvas":"","hide-inputs":"","hide-mode-switch":"","mode":"hexa","show-swatches":"","swatches-max-height":"120"},model:{value:(_vm.rule.backgroundColor),callback:function ($$v) {_vm.$set(_vm.rule, "backgroundColor", $$v)},expression:"rule.backgroundColor"}})],1),_c('div',{staticClass:"ma-3"},[_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"mt-0 mb-2",attrs:{"label":_vm.$t('textColor'),"dense":"","hide-details":""},model:{value:(_vm.rule.textColorMode),callback:function ($$v) {_vm.$set(_vm.rule, "textColorMode", $$v)},expression:"rule.textColorMode"}})],1),_c('v-color-picker',{attrs:{"disabled":!_vm.rule.textColorMode,"hide-canvas":"","hide-inputs":"","hide-mode-switch":"","mode":"hexa","show-swatches":"","swatches-max-height":"120"},model:{value:(_vm.rule.textColor),callback:function ($$v) {_vm.$set(_vm.rule, "textColor", $$v)},expression:"rule.textColor"}})],1)])])],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="layer">
      <v-card-actions>
        <v-breadcrumbs :items="breadcrumbs"/>
        <v-spacer/>
        <action-toolbar
          :actions="actions"
          :bottom="true"
          @download="exportCsv"
          @history="openHistory"
          @removeAll="$refs.confirmationDialog.open(layer)"
        />
      </v-card-actions>
      <v-card-actions>
        <span style="width: 180px">
          <v-select
            v-model="filterBy"
            :items="filters"
            class="group-input-left"
            dense
            hide-details
            item-text="name"
            label="Фильтр"
            outlined
            prepend-icon="mdi-filter"
            return-object
          />
        </span>

        <span style="width: 180px">
          <v-select
            v-if="filterBy.type === 'LIST' || filterBy.type === 'BOOLEAN'"
            v-model="filterValue"
            :items="getOptions()"
            class="group-input-right"
            clearable
            dense
            hide-details
            label="Значение"
            outlined
            single-line
          >
            <template v-slot:item="{ item }">
              <span v-if="filterBy.type === 'BOOLEAN'" class="text-truncate"> {{ $t(item) }} </span>
              <span v-else class="text-truncate">{{ item }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="filterBy.type === 'BOOLEAN'" class="text-truncate"> {{ $t(item) }} </span>
              <span v-else class="text-truncate">{{ item }}</span>
            </template>
          </v-select>
          <v-text-field
            v-else
            v-model="filterValue"
            class="group-input-right"
            clearable
            dense
            hide-details
            label="Значение"
            outlined
          />
        </span>
        <v-spacer/>
        <v-select
          v-if="layer.poiTemplates.length > 1"
          v-model="selectedTemplate"
          :items="layer.poiTemplates"
          dense
          hide-details
          item-text="name"
          label="Тип объектов"
          outlined
          return-object
        />
      </v-card-actions>

      <v-card-text class="ma-0 pa-0" style="overflow: auto">

        <v-data-table
          :id="!displayXS ? 'layer-poi-table-fixed' : ''"
          :headers="headers.filter(f => !f.excludeFromTableView)"
          :items="items"
          :options.sync="options"
          :server-items-length="totalElements"
          :style="{paddingBottom: displayXS ? '56px' : ''}"
          locale="ru"
          :loading="loading"
          :loading-text="$t('loading')"
        >

          <template v-slot:item="{ item, headers }">
            <tr @click="openDialog(item)">
              <td
                v-for="(header, index) in headers"
                :key="index"
              >
                <template v-if="header.type === 'LINK'">
                  <a
                    :href="item[header.value]"
                    target="_blank"
                  >
                    {{ item[header.value] }}
                  </a>
                </template>

                <template v-else-if="header.type === 'NUMBER' ||  header.type === 'COMPUTED_FIELD'">
                  {{
                    item[header.value] ? item[header.value].toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') : ''
                  }}
                </template>

                <template v-else-if="header.type === 'CUSTOM_LINK'">
                  <a
                    :href="item[header.options] + item[header.value]"
                    target="_blank"
                  >
                    {{ item[header.value] }}
                  </a>
                </template>

                <template v-else-if="header.type === 'PARAMETRIC_LINK'">
                  <v-hover v-slot="{ hover }" open-delay="700">
                    <div style="position: relative">
                      <v-menu :value="hover" offset-y>
                        <template v-slot:activator="{on}">
                          <a
                            :href="createParametricLink(header.options, item)"
                            target="_blank"
                          >
                            {{ $t('link') }}
                          </a>
                        </template>
                        <v-card>
                          <v-card-text>
                            {{ createParametricLink(header.options, item) }}
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                  </v-hover>
                </template>

                <template v-else-if="header.type === 'BOOLEAN'">
                  {{ item[header.value] ? $t('true') : $t('false') }}
                </template>

                <template v-else>
                  {{ item[header.value] }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
    <confirmation-dialog
      ref="confirmationDialog"
      title="<span class='red--text font-weight-bold'>Внимание, это действие необратимо!</span><br/>Вместе с этой операцией история изменений по объектам также будет удалена.<br/> Вы действительно хотите удалить все данные типа: "
      @confirm="removeAll"
    />
    <layer-poi-dialog
      ref="layerPoiDlg"
      actions-toolbar
      map-widget
      @update="loadPoiList"
    />
    <layer-poi-changelog-table
      ref="layerPoiChangelogTable"
    />
  </div>
</template>

<script>

import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import LayerPoiChangelogTable from '@/components/layer-poi/LayerPoiChangelogTable.vue'
import ActionToolbar from '@/components/utils/ActionToolbar'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import messages from '@/componet-locale/report-view/messages'
import { EventBus } from '@/event-bus'
import { HISTORY } from '@/components/map/helpers/map-actions'
import { displayServiceMixin } from "@/mixins/dispay-service-mixin";

export default {
  name: 'LayerPoiTable',
  components: { ActionToolbar, ConfirmationDialog, LayerPoiDialog, LayerPoiChangelogTable },
  i18n: { messages },
  mixins: [displayServiceMixin],
  data: () => ({
    selectedTemplate: null,
    layer: null,
    headers: [],
    filters: [],
    filterBy: {},
    filterValue: '',
    options: {},
    totalElements: 0,
    items: [],
    fieldValueMapper: {
      LINK: 'value',
      CUSTOM_LINK: 'value',
      LIST: 'value',
      MULTIPLE_LIST: 'value',
      BOOLEAN: 'boolean',
      STRING: 'string',
      NUMBER: 'number',
      DATE: 'date',
      COMPUTED_FIELD: 'value',
      DATA_TABLE: 'value'
    },
    actions: [
      HISTORY,
      {
        title: 'deleteAll',
        icon: 'delete_outline',
        color: 'red',
        background: '#ffe0e0',
        action: 'removeAll',
        userRole: 'ADMIN'
      },
      {
        title: 'export',
        icon: 'file_download',
        color: 'white',
        primary: true,
        background: '#2d89ff',
        action: 'download'
      }
    ],
    cancel: undefined,
    loading: false
  }),
  created () {
    this.init()
  },
  methods: {
    openHistory () {
      this.$refs.layerPoiChangelogTable.open(this.$route.params.id)
    },
    exportCsv () {
      const body = this.getRequestBody()
      this.$axios
        .post(`/layer-poi/export-layer/xlsx`, body, {
          responseType: 'arraybuffer',
          params: { layerId: this.layer.id, templateId: this.selectedTemplate.id }
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          let name = this.selectedTemplate.name || 'layer-poi'
          link.download = `${name}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
    },
    removeAll (layer) {
      this.$axios.delete('layer-poi/delete-by-template', {
        params: { layerId: layer.id, templateId: this.selectedTemplate.id },
        timeout: 60000
      })
        .then(() => {
          this.loadPoiList()
          EventBus.$emit('showSuccessMessage', 'Данные удалены')
        })
        .catch(() =>
          EventBus.$emit('showErrorMessage', 'Произошла ошибка'))
    },
    getOptions () {
      if (this.filterBy.type === 'BOOLEAN') return ['true', 'false']
      else if (this.filterBy.type === 'LIST') return this.filterBy.options.split(',')
    },
    async init () {
      let layerId = this.$route.params.id
      let response = await this.$axios
        .get('data/get', {
          params: { id: layerId }
        })

      this.layer = response.data
      this.selectedTemplate = this.layer.poiTemplates[0] || null
    },
    getRequestBody () {
      const body = {
        page: this.options.page - 1,
        rowsPerPage: this.options.itemsPerPage
      }
      if (Object.keys(this.filterBy).length && this.filterValue) {
        body.layerPoiCriteria = {
          fieldId: this.filterBy.id,
          dataType: this.filterBy.type,
          value: this.filterValue
        }
      }
      return body
    },
    loadPoiList () {
      const CancelToken = this.$axios.CancelToken
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      const body = this.getRequestBody()
      let requestParam = {
        layerId: this.layer.id,
        templateId: this.selectedTemplate.id
      }
      this.loading = true
      this.$axios
        .post('layer-poi/filter', body, {
          params: requestParam,
          cancelToken: new CancelToken(function executor (c) {
            self.cancel = c
          })
        })
        .then(({ data }) => {
          this.totalElements = data.totalElements
          this.populateTableContent(data.content)
          this.loading = false
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            this.loading = false
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    populateTableContent (data) {
      this.items = data
        .map(d =>
          d.properties.reduce((previous, property) => {
            const key = this.fieldValueMapper[property.field.type]
            previous[property.field.name] = property[key]
            previous._obj = d
            return previous
          }, {})
        )
    },
    setHeadersFromTemplate (template) {
      this.headers = template.fields.map(f =>
        (
          {
            text: f.name,
            value: f.name,
            sortable: false,
            ...f
          }
        )
      )
      this.options = { page: 1, itemsPerPage: 10 }
    },
    updateFilter (event, column) {
      this.filter[column.id].second = event
      this.loadPoiList()
    },
    initFilter (fields) {
      this.filterBy = {}
      this.filters = []
      fields.forEach(f => {
        if (
          f.type === 'STRING' ||
          f.type === 'LIST' ||
          f.type === 'BOOLEAN'
        ) {
          this.filters.push(f)
        }
      })
    },
    openDialog (item) {
      let poi = { ...item._obj }
      this.$refs.layerPoiDlg.open(poi)
    },
    createParametricLink (value, poi) {
      function findValue (fieldName) {
        let value = poi._obj.properties.find(prop => prop.field.name === fieldName)
        if (!value) {
          return ""
        }
        return value.string || value.value || value.field.options || ''
      }

      function replacer (val) {
        let fieldName = val
          .replaceAll('[', '')
          .replaceAll(']', '')
        return findValue(fieldName)
      }

      return value.replaceAll(/\[.*?\]/g, replacer)

    }
  },
  watch: {
    selectedTemplate (val) {
      this.setHeadersFromTemplate(val)
      this.initFilter(val.fields)
      this.loadPoiList()
    },
    filterValue () {
      this.loadPoiList()
    },
    'options.page' () {
      this.loadPoiList()
    },
    'options.itemsPerPage' () {
      this.loadPoiList()
    }
  },
  computed: {
    displayServiceMixin () {
      return displayServiceMixin
    },
    breadcrumbs () {
      return [
        {
          text: this.selectedProject.name,
          to: `/project/${this.$route.params.projectId}/home`
        },
        {
          text: 'Объекты',
          to: `/project/${this.$route.params.projectId}/poi-table`,
          exact: true
        },
        {
          text: this.layer ? this.layer.name : ' - ',
          disabled: true
        }
      ]
    }
  }

}
</script>

<style>
#layer-poi-table-fixed .v-data-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
}

#layer-poi-table-fixed .v-data-table__wrapper {
  margin-bottom: 60px;
}
</style>

<template>
  <div>
    <div
      v-if="selectedProject.id"
      :class="isCategoriesBarOpen ? 'project-expanded' : ''"
    >
      <div
        v-show="isCategoriesBarOpen"
        id="project-data-bar"
        style="z-index: 1; background-color: white; border-right: solid 1px #d6d2d2;"
      >
        <v-card-title class="pl-1 py-2" style="height: 49px">
          <div id="header-of-project" class="ml-2">Категории</div>
          <v-spacer/>
          <v-btn v-if="!displayXS" icon @click="isCategoriesBarOpen = !isCategoriesBarOpen">
            <v-icon>{{ isCategoriesBarOpen ? 'arrow_left' : 'arrow_right' }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-treeview
          :active="[selectedCategoryId]"
          :items="categoryTree"
          :open="openTypes"
          activatable
          class="pt-2"
          hoverable
          item-text="name"
          open-on-click
          style="font-size: 12px"
          @update:active="selectCategory"
        />
      </div>
      <div
        v-if="!displayXS"
        v-show="!isCategoriesBarOpen"
        style="cursor: pointer; background-color: white; border-right: solid 1px #d6d2d2; min-height: 100vh; width: 36px; position: fixed; z-index: 1"
        @click="isCategoriesBarOpen = true"
      >
        <v-btn class="mb-2" icon>
          <v-icon>{{ isCategoriesBarOpen ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
        <div
          class="body-1"
          style="padding: 5px; width: 1000px; overflow: hidden; margin-top: -30px; transform-origin: bottom left; transform: rotate(90deg);"
        >
          {{ $t('categories') }}
        </div>
      </div>
    </div>
    <v-container>
      <div :style="{marginLeft: getViewerLeftMargin()}">
        <router-view/>
      </div>
    </v-container>
  </div>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../../componet-locale/category-view/messages'
import CategoryViewer from '@/components/category/CategoryViewer.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'CategoryView',
  components: { CategoryViewer },
  i18n: { messages },
  mixins: [displayServiceMixin],
  data: () => ({
    isCategoriesBarOpen: false,
    categoryList: [],
    openTypes: []
  }),
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.loadCategories()
    },
    loadCategories () {
      this.$axios.get('/category/get-category-details-list', {
        params: {
          id: this.$route.params.projectId,
        }
      })
        .then(res => this.categoryList = res.data.map(el => el.category))
        .catch(() => EventBus.$emit('showErrorMessages', this.$t('error')))
    },
    selectCategory (ids) {
      if (!ids[0] || ids[0] === this.selectedCategoryId) return
      this.$router.push({ path: `/project/${this.$route.params.projectId}/category/${ids[0]}` })
    },
    getViewerLeftMargin () {
      if (this.displayXS) {
        return ''
      } else if (this.isCategoriesBarOpen) {
        return '280px'
      } else {
        return '35px'
      }
    }
  },
  computed: {
    selectedCategoryId () {
      return +this.$route.params.id
    },
    categoryTree () {
      const categoryTree = []
      const categoryGroupedByType = Object
        .groupBy(this.categoryList, category => category.type || '')
      Object.keys(categoryGroupedByType)
        .forEach((key, i) => {
          categoryTree.push({
            children: categoryGroupedByType[key],
            name: key !== 'null' && key ? key : this.$t('withoutType'),
            id: i
          })
        })
      return categoryTree
    }
  },
  watch: {
    categoryTree () {
      const open = this.categoryTree.find(categoryGroup => categoryGroup.children.some(category => category.id === this.selectedCategoryId))
      if (open) this.openTypes.push(open.id)
    }
  }
}
</script>
